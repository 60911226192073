'use client';
import { Layer } from 'react-map-gl';
import { useMapTheme } from '../../context/map-context';

export function Background() {
  const config = useMapTheme();
  return (
    <Layer
      id="background"
      type="background"
      layout={{ visibility: 'visible' }}
      paint={{
        'background-color': config.theme.land,
        'background-opacity': 1,
      }}
    />
  );
}
