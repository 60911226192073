'use client';
import { Layer } from 'react-map-gl';
import { useMapTheme } from '../../context/map-context';

export function BoundaryCountryLayers() {
  const config = useMapTheme();
  return (
    <>
      <Layer
        {...{
          id: 'boundary_country_outline',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'boundary',
          minzoom: 6,
          maxzoom: 24,
          filter: ['all', ['==', 'admin_level', 2], ['==', 'maritime', 0]],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': config.theme.bounds,
            // 'line-opacity': 0.5,
            'line-width': 8,
            'line-offset': 0,
          },
        }}
      />
      <Layer
        {...{
          id: 'boundary_country_inner',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'boundary',
          minzoom: 0,
          filter: ['all', ['==', 'admin_level', 2], ['==', 'maritime', 0]],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': config.theme.bounds,
            // 'line-color': {
            //   stops: [
            //     [
            //       4,
            //       cn({
            //         '#f2e6e7': config.baseTheme === 'light',
            //         'rgba(92, 94, 94, 1)': config.baseTheme === 'dark',
            //       }),
            //     ],
            //     [
            //       5,
            //       cn({
            //         '#ebd6d8': config.baseTheme === 'light',
            //         'rgba(96, 96, 96, 1)': config.baseTheme === 'dark',
            //       }),
            //     ],
            //     [
            //       6,
            //       cn({
            //         '#ebd6d8': config.baseTheme === 'light',
            //         'rgba(102, 102, 102, 1)': config.baseTheme === 'dark',
            //       }),
            //     ],
            //   ],
            // },
            'line-opacity': 1,
            'line-width': {
              stops: [
                [3, 1],
                [6, 1.5],
              ],
            },
            'line-offset': 0,
          },
        }}
      />
    </>
  );
}
