'use client';

import { useState } from 'react';
import { Marker, Popup } from 'react-map-gl';

interface MarkerWithPopupProps {
  lat: number;
  lng: number;
  marker: React.ReactNode;
  popup?: React.ReactNode;
}

/**
 * Map marker with an optional popup.
 */
export function MarkerWithPopup({
  lat,
  lng,
  marker,
  popup,
}: MarkerWithPopupProps) {
  const [showPopup, setShowPopup] = useState(false);
  return (
    <>
      <Marker
        longitude={lng}
        latitude={lat}
        anchor="center"
        onClick={e => {
          e.originalEvent.stopPropagation();
          if (!!popup) {
            setShowPopup(true);
          }
        }}>
        {marker}
      </Marker>
      {showPopup ? (
        <Popup
          anchor="top"
          longitude={lng}
          latitude={lat}
          closeButton={false}
          onClose={() => setShowPopup(false)}>
          {popup}
        </Popup>
      ) : null}
    </>
  );
}
