import { colors } from './colors';
import { type MapColorTheme } from './map-theme';

export const DEFAULT_THEME = {
  name: 'Default',
  complete: {
    light: colors.blue._400(),
    dark: colors.blue._600(),
  },
  complete_selected: {
    light: colors.blue._600(),
    dark: colors.blue._600(),
  },
  incomplete: {
    light: colors.red._800(),
    dark: colors.red._900(),
  },
  incomplete_selected: {
    light: colors.red._900(),
    dark: colors.red._900(),
  },
  bounds: {
    light: colors.zinc._700(),
    dark: colors.zinc._500(),
  },
  bounds_progress_min: {
    light: colors.red._200(),
    dark: colors.red._800(),
  },
  bounds_progress_mid: {
    light: colors.indigo._300(),
    dark: colors.indigo._700(),
  },
  bounds_progress_max: {
    light: colors.green._700(),
    dark: colors.green._300(),
  },
  hover: {
    light: colors.amber._400(),
    dark: colors.amber._600(),
  },
  water: {
    light: colors.slate._300(),
    dark: colors.slate._800(),
  },
  waterway: {
    light: colors.blue._300(),
    dark: colors.slate._950(),
  },
  land: {
    light: colors.zinc._100(),
    dark: colors.zinc._950(),
  },
  building: {
    light: colors.zinc._300(),
    dark: colors.zinc._900(),
  },
  building_outline: {
    light: colors.zinc._500(),
    dark: colors.slate._900(),
  },
  road: {
    light: colors.zinc._600(),
    dark: colors.zinc._500(),
  },
  bridge_outline: {
    light: colors.zinc._300(),
    dark: colors.zinc._100(),
  },
  road_outline: {
    light: colors.zinc._800(),
    dark: colors.zinc._400(),
  },
  road_exclude: {
    light: colors.zinc._200(),
    dark: colors.zinc._900(),
  },
  bridge_exclude_outline: {
    light: colors.zinc._400(),
    dark: colors.zinc._700(),
  },
  road_exclude_outline: {
    light: colors.zinc._300(),
    dark: colors.zinc._800(),
  },
  roadText: {
    light: colors.zinc._50(),
    dark: colors.zinc._50(),
  },
  roadHalo: {
    light: colors.zinc._950(),
    dark: colors.zinc._800(),
  },
} as const satisfies MapColorTheme;
export const COLORBLIND_THEME = {
  ...DEFAULT_THEME,
  name: 'Colorblind Friendly',
  bounds: {
    light: 'hsl(40, 100%, 45%)', // Adjusted orange for better distinction
    dark: 'hsl(40, 100%, 45%)', // Adjusted orange for better distinction
  },
} as const satisfies MapColorTheme;

export const AllThemes = [DEFAULT_THEME, COLORBLIND_THEME] as const;
export const ThemeNames = AllThemes.map(t => t.name);
export type ThemeName = (typeof ThemeNames)[number];
