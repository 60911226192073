'use client';
import { useMapTheme } from '../../context/map-context';
import { useLineLayer } from '../layer-helpers';
import {
  type LinePaint,
  type Expression,
  type Layer,
  type LineLayer,
  type LineLayout,
} from 'mapbox-gl';
import { type ResolvedMapColorTheme } from '../../context/map-theme';

const getClass = ['get', 'class'];
const isRamp = ['==', ['get', 'ramp'], 1];

function rdExpr(classes: string | string[]): Expression {
  const getClass: Expression = ['get', 'class'];

  if (typeof classes === 'string') {
    return ['==', getClass, classes];
  } else if (Array.isArray(classes)) {
    if (classes.length === 1) {
      return ['==', getClass, classes[0]];
    } else {
      return ['match', getClass, classes, true, false];
    }
  }
  throw new Error(
    'Invalid input: classes should be a string or an array of strings.',
  );
}

const roadClasses = [
  'motorway',
  'trunk',
  'primary',
  'secondary',
  'tertiary',
  'minor',
  'service',
  'path',
];

//Determine which zoom to show road type
function getLineOpacityExpression(): Expression {
  return [
    'step',
    ['zoom'],
    0, // Default opacity before the first step (zoom < 10)
    10,
    ['match', getClass, roadClasses.slice(0, 3), 1, 0], // Matches the first 3 road classes
    11,
    ['match', getClass, roadClasses.slice(0, 6), 1, 0], // Matches the first 6 road classes
    15,
    ['match', getClass, roadClasses, 1, 0], // Matches all road classes
  ];
}

const baseZoomLevels = [
  //Ramp, then motorway down to path
  { zoom: 12, values: [0, 5, 1, 0.5, 0.5, 0.3, 0, 0] },
  { zoom: 13, values: [1.5, 6, 2.5, 2.5, 2, 1.5, 1, 0] },
  { zoom: 14, values: [2, 7, 2.5, 2.5, 2.5, 2, 1.5, 0] },
  { zoom: 15, values: [3, 9, 4, 4, 4, 3, 2.5, 0.5] },
  { zoom: 16, values: [6, 12, 5, 5, 5, 4.3, 3, 1] },
  { zoom: 17, values: [8, 14, 5, 5, 5, 4, 4.3, 2] },
  { zoom: 18, values: [8, 22, 5, 5, 5, 4, 5, 3] },
];

//Width of the casing, divide by 2 to get pixel width on each side
const casingWidth = 3;

function getLineWidthExpression(casing: boolean): Expression {
  const zoomLevels = baseZoomLevels.map(level => {
    const adjustedValues = casing
      ? level.values.map(value => (value >= 3 ? value + casingWidth : value)) // Add 1 to values >= 2
      : level.values;
    return { ...level, values: adjustedValues };
  });
  return [
    'interpolate',
    ['linear'],
    ['zoom'],
    ...zoomLevels.flatMap(({ zoom, values }) => [
      zoom,
      [
        'case',
        isRamp,
        values[0],
        rdExpr('motorway'),
        values[1],
        rdExpr('trunk'),
        values[2],
        rdExpr('primary'),
        values[3],
        rdExpr(['secondary', 'tertiary']),
        values[4],
        rdExpr('minor'),
        values[5],
        rdExpr('service'),
        values[6],
        rdExpr('path'),
        values[7],
        0, // Default case
      ],
    ]),
  ];
}

function excludedRoad(): Expression {
  return [
    'any',
    isRamp,
    ['match', getClass, ['service', 'motorway', 'path'], true, false],
    ['==', ['get', 'expressway'], 1],
    ['==', ['get', 'access'], 'no'],
  ];
}

function roadLinePaint(
  theme: ResolvedMapColorTheme,
  bridge: boolean,
  casing: boolean,
): LinePaint {
  return {
    'line-opacity': getLineOpacityExpression(),
    'line-width': getLineWidthExpression(casing),
    'line-color': [
      'case',
      excludedRoad(),
      // If any of the conditions are true, use road_exclude
      casing
        ? bridge
          ? theme.bridge_exclude_outline
          : theme.road_exclude_outline
        : theme.road_exclude,
      // Otherwise, use the default road color
      casing
        ? bridge
          ? theme.bridge_outline
          : theme.road_outline
        : theme.road,
    ],
  };
}

function lineLayout(casing: boolean): LineLayout {
  return {
    'line-cap': casing ? 'butt' : 'round',
    'line-join': 'round',
  };
}

function roadLayer(
  id: string,
  theme: ResolvedMapColorTheme,
  casing: boolean,
  bridge: boolean,
): LineLayer {
  const bridgeFilter = bridge
    ? ['==', 'brunnel', 'bridge']
    : ['!has', 'brunnel'];
  const layerConfig = {
    id,
    type: 'line' as const,
    source: 'openmaptiles' as const,
    'source-layer': 'transportation' as const,
    layout: lineLayout(casing),
    filter: bridgeFilter,
    paint: roadLinePaint(theme, bridge, casing),
  };
  return layerConfig;
}

function useRoad(id: string, bridge: boolean) {
  const config = useMapTheme();
  const casing = useLineLayer(
    roadLayer(`${id}_casing`, config.theme, true, bridge),
  );
  const fill = useLineLayer(
    roadLayer(`${id}_fill`, config.theme, false, bridge),
  );
  return {
    casing,
    fill,
  };
}

interface RoadLayerProps {
  /**
   * Unique identifier for the layer. Prefix with _ by convention.
   */
  id: string;
  bridge: boolean;
}

export function RoadLayers({ id, bridge }: RoadLayerProps) {
  const Road = useRoad(id, bridge);
  return (
    <>
      {Road.casing}
      {Road.fill}
    </>
  );
}
