'use client';
import { Layer } from 'react-map-gl';
import { SATELLITE_SOURCE_ID, SatelliteSource } from './satellite-source';

interface SatelliteProps {
  beforeId: string;
}
export function Satellite({ beforeId }: SatelliteProps) {
  return (
    <SatelliteSource>
      <Layer
        id={SATELLITE_SOURCE_ID}
        type="raster"
        source={SATELLITE_SOURCE_ID}
        minzoom={0}
        maxzoom={18.5}
        layout={{
          visibility: 'visible',
        }}
        beforeId={beforeId}
      />
    </SatelliteSource>
  );
}
