'use client';

import { type ActivityType } from '@streetferret/api';
import { useMemo } from 'react';
import { Layer } from 'react-map-gl';
import { useMapTheme } from '../../context/map-context';
import { lineWidthScale } from '../../utils/layer-utils';
import { USER_TILES_SOURCE_ID } from './user-tiles-source';

export type ProgressLayerFilterState =
  | 'all'
  | 'complete'
  | 'incomplete'
  | 'none';

const ATTR_COMPLETE = ['==', ['get', 'complete']];

export interface ProgressLayersProps {
  filterState: ProgressLayerFilterState;
  activityType: ActivityType;
  selectedStreetIds?: string[] | null;
  hoveredStreetIds?: string[] | null;
  /**
   * if provided, only show progress for these cities.
   */
  cityIncludeFilter?: number[];
  /**
   * if provided, exclude these cities.
   */
  cityExcludeFilter?: number[];
  hidden?: boolean;
}
export function ProgressLayers({
  activityType,
  filterState,
  selectedStreetIds,
  hoveredStreetIds,
  cityIncludeFilter,
  cityExcludeFilter,
  hidden,
}: ProgressLayersProps) {
  const { theme } = useMapTheme();
  const isRunType = useMemo(() => activityType === 'run', [activityType]);
  const visibility = useMemo(() => (hidden ? 'none' : 'visible'), [hidden]);

  const filterShowCities = useMemo(() => {
    return [
      'all',
      cityIncludeFilter?.length
        ? ['in', ['get', 'cityID'], ['literal', cityIncludeFilter]]
        : true,
      ['!', ['in', ['get', 'cityID'], ['literal', cityExcludeFilter ?? []]]],
    ];
  }, [cityIncludeFilter, cityExcludeFilter]);

  const filterShowComplete = useMemo(
    () => ['any', [...ATTR_COMPLETE, isRunType ? 1 : 2], [...ATTR_COMPLETE, 3]],
    [isRunType],
  );
  const filterShowIncomplete = useMemo(
    () => ['any', [...ATTR_COMPLETE, 0], [...ATTR_COMPLETE, isRunType ? 2 : 1]],
    [isRunType],
  );
  const filterShowNothing = useMemo(() => ['literal', false], []);

  const completeFilter = useMemo(() => {
    if (filterState === 'all' || filterState === 'complete') {
      return ['all', filterShowComplete];
    }
    return filterShowNothing;
  }, [filterState, filterShowComplete, filterShowNothing]);

  const incompleteFilter = useMemo(() => {
    if (filterState === 'all' || filterState === 'incomplete') {
      return ['all', filterShowIncomplete];
    }
    return filterShowNothing;
  }, [filterState, filterShowIncomplete, filterShowNothing]);

  const filterShowSelected = useMemo(
    () => ['in', ['get', 'id'], ['literal', selectedStreetIds ?? []]],
    [selectedStreetIds],
  );
  const filterShowHover = useMemo(
    () => ['in', ['get', 'id'], ['literal', hoveredStreetIds ?? []]],
    [hoveredStreetIds],
  );

  return (
    <>
      <Layer
        id="progress-hover-inner"
        source={USER_TILES_SOURCE_ID}
        source-layer="progress"
        type="line"
        paint={{
          'line-width': lineWidthScale(2.5),
          'line-color': theme.hover,
        }}
        layout={{
          visibility,

          'line-join': 'round',
          'line-cap': 'round',
        }}
        filter={['all', filterShowHover, filterShowCities]}
        minzoom={9}
        beforeId="_road_overlays"
      />
      {/* Incomplete and complete progress are deliberately separate layers 
      so that the incomplete is always on top where they overlap. */}
      <Layer
        id="progress-incomplete"
        source={USER_TILES_SOURCE_ID}
        source-layer="progress"
        type="line"
        filter={['all', incompleteFilter, filterShowCities]}
        paint={{
          'line-width': lineWidthScale(1.3),
          'line-color': theme.incomplete,
          'line-opacity': 1,
        }}
        layout={{
          visibility,
          'line-join': 'round',
          'line-cap': 'round',
        }}
        minzoom={9}
        beforeId="_road_overlays"
      />
      <Layer
        id="progress-complete"
        source={USER_TILES_SOURCE_ID}
        source-layer="progress"
        type="line"
        filter={['all', completeFilter, filterShowCities]}
        paint={{
          'line-width': lineWidthScale(1.3),
          'line-color': theme.complete,
          'line-opacity': 1,
        }}
        layout={{
          visibility,
          'line-join': 'round',
          'line-cap': 'round',
        }}
        minzoom={9}
        beforeId="_road_overlays"
      />
      <Layer
        id="selected-outer"
        source={USER_TILES_SOURCE_ID}
        source-layer="progress"
        type="line"
        paint={{
          'line-width': lineWidthScale(8),
          'line-color': theme.hover,
        }}
        layout={{
          visibility,
          'line-join': 'round',
          'line-cap': 'round',
        }}
        filter={['all', filterShowSelected, filterShowCities]}
        minzoom={9}
        beforeId="_road_overlays"
      />

      <Layer
        id="selected-inner"
        source={USER_TILES_SOURCE_ID}
        source-layer="progress"
        type="line"
        paint={{
          'line-width': lineWidthScale(2.5),
          'line-color': [
            'case',
            filterShowComplete,
            theme.complete_selected,
            theme.incomplete_selected,
          ],
        }}
        layout={{
          visibility,
          'line-join': 'round',
          'line-cap': 'round',
        }}
        filter={['all', filterShowSelected, filterShowCities]}
        minzoom={9}
        beforeId="_road_overlays"
      />
    </>
  );
}
