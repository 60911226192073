type HSLValues = [number, number, number];
function hsl(...values: HSLValues) {
  return (alpha?: number) => {
    const [h, s, l] = values;
    const inner = `${h},${s}%,${l}%`;
    if (alpha) {
      return `hsla(${inner},${alpha})`;
    }
    return `hsl(${inner})`;
  };
}
const lime = {
  _50: hsl(78.3, 92, 95.1),
  _100: hsl(79.6, 89.1, 89.2),
  _200: hsl(80.9, 88.5, 79.6),
  _300: hsl(82, 84.5, 67.1),
  _400: hsl(82.7, 78, 55.5),
  _500: hsl(83.7, 80.5, 44.3),
  _600: hsl(84.8, 85.2, 34.5),
  _700: hsl(85.9, 78.4, 27.3),
  _800: hsl(86.3, 69, 22.7),
  _900: hsl(87.6, 61.2, 20.2),
  _950: hsl(89.3, 80.4, 10),
};
const slate = {
  _50: hsl(210, 40, 98),
  _100: hsl(210, 40, 96.1),
  _200: hsl(214.3, 31.8, 91.4),
  _300: hsl(212.7, 26.8, 83.9),
  _400: hsl(215, 20.2, 65.1),
  _500: hsl(215.4, 16.3, 46.9),
  _600: hsl(215.3, 19.3, 34.5),
  _700: hsl(215.3, 25, 26.7),
  _800: hsl(217.2, 32.6, 17.5),
  _900: hsl(222.2, 47.4, 11.2),
  _950: hsl(222.2, 84, 4.9),
};
const zinc = {
  _50: hsl(0, 0, 98),
  _100: hsl(240, 4.8, 95.9),
  _200: hsl(240, 5.9, 90),
  _300: hsl(240, 4.9, 83.9),
  _400: hsl(240, 5, 64.9),
  _500: hsl(240, 3.8, 46.1),
  _600: hsl(240, 5.2, 33.9),
  _700: hsl(240, 5.3, 26.1),
  _800: hsl(240, 3.7, 15.9),
  _900: hsl(240, 5.9, 10),
  _950: hsl(240, 10, 3.9),
};
const gray = {
  _50: hsl(210, 20, 98),
  _100: hsl(220, 14.3, 95.9),
  _200: hsl(220, 13, 91),
  _300: hsl(216, 12.2, 83.9),
  _400: hsl(217.9, 10.6, 64.9),
  _500: hsl(220, 8.9, 46.1),
  _600: hsl(215, 13.8, 34.1),
  _700: hsl(216.9, 19.1, 26.7),
  _800: hsl(215, 27.9, 16.9),
  _900: hsl(220.9, 39.3, 11),
  _950: hsl(224, 71.4, 4.1),
};
const neutral = {
  _50: hsl(0, 0, 98),
  _100: hsl(0, 0, 96.1),
  _200: hsl(0, 0, 89.8),
  _300: hsl(0, 0, 83.1),
  _400: hsl(0, 0, 63.9),
  _500: hsl(0, 0, 45.1),
  _600: hsl(0, 0, 32.2),
  _700: hsl(0, 0, 25.1),
  _800: hsl(0, 0, 14.9),
  _900: hsl(0, 0, 9),
  _950: hsl(0, 0, 3.9),
};
const stone = {
  _50: hsl(60, 9.1, 97.8),
  _100: hsl(60, 4.8, 95.9),
  _200: hsl(20, 5.9, 90),
  _300: hsl(24, 5.7, 82.9),
  _400: hsl(24, 5.4, 63.9),
  _500: hsl(25, 5.3, 44.7),
  _600: hsl(33.3, 5.5, 32.4),
  _700: hsl(30, 6.3, 25.1),
  _800: hsl(12, 6.5, 15.1),
  _900: hsl(24, 9.8, 10),
  _950: hsl(20, 14.3, 4.1),
};
const cyan = {
  _50: hsl(183.2, 100, 96.3),
  _100: hsl(185.1, 95.9, 90.4),
  _200: hsl(186.2, 93.5, 81.8),
  _300: hsl(187, 92.4, 69),
  _400: hsl(187.9, 85.7, 53.3),
  _500: hsl(188.7, 94.5, 42.7),
  _600: hsl(191.6, 91.4, 36.5),
  _700: hsl(192.9, 82.3, 31),
  _800: hsl(194.4, 69.6, 27.1),
  _900: hsl(196.4, 63.6, 23.7),
  _950: hsl(197, 78.9, 14.9),
};
const sky = {
  _50: hsl(204, 100, 97.1),
  _100: hsl(204, 93.8, 93.7),
  _200: hsl(200.6, 94.4, 86.1),
  _300: hsl(199.4, 95.5, 73.9),
  _400: hsl(198.4, 93.2, 59.6),
  _500: hsl(198.6, 88.7, 48.4),
  _600: hsl(200.4, 98, 39.4),
  _700: hsl(201.3, 96.3, 32.2),
  _800: hsl(201, 90, 27.5),
  _900: hsl(202, 80.3, 23.9),
  _950: hsl(204, 80.2, 15.9),
};
const blue = {
  _50: hsl(213.8, 100, 96.9),
  _100: hsl(214.3, 94.6, 92.7),
  _200: hsl(213.3, 96.9, 87.3),
  _300: hsl(211.7, 96.4, 78.4),
  _400: hsl(213.1, 93.9, 67.8),
  _500: hsl(217.2, 91.2, 59.8),
  _600: hsl(221.2, 83.2, 53.3),
  _700: hsl(224.3, 76.3, 48),
  _800: hsl(225.9, 70.7, 40.2),
  _900: hsl(224.4, 64.3, 32.9),
  _950: hsl(226.2, 57, 21),
};
const red = {
  _50: hsl(0, 85.7, 97.3),
  _100: hsl(0, 93.3, 94.1),
  _200: hsl(0, 96.3, 89.4),
  _300: hsl(0, 93.5, 81.8),
  _400: hsl(0, 90.6, 70.8),
  _500: hsl(0, 84.2, 60.2),
  _600: hsl(0, 72.2, 50.6),
  _700: hsl(0, 73.7, 41.8),
  _800: hsl(0, 70, 35.3),
  _900: hsl(0, 62.8, 30.6),
  _950: hsl(0, 74.7, 15.5),
};
const orange = {
  _50: hsl(33.3, 100, 96.5),
  _100: hsl(34.3, 100, 91.8),
  _200: hsl(32.1, 97.7, 83.1),
  _300: hsl(30.7, 97.2, 72.4),
  _400: hsl(27, 96, 61),
  _500: hsl(24.6, 95, 53.1),
  _600: hsl(20.5, 90.2, 48.2),
  _700: hsl(17.5, 88.3, 40.4),
  _800: hsl(15, 79.1, 33.7),
  _900: hsl(15.3, 74.6, 27.8),
  _950: hsl(13, 81.1, 14.5),
};
const amber = {
  _50: hsl(48, 100, 96.1),
  _100: hsl(48, 96.5, 88.8),
  _200: hsl(48, 96.6, 76.7),
  _300: hsl(45.9, 96.7, 64.5),
  _400: hsl(43.3, 96.4, 56.3),
  _500: hsl(37.7, 92.1, 50.2),
  _600: hsl(32.1, 94.6, 43.7),
  _700: hsl(26, 90.5, 37.1),
  _800: hsl(22.7, 82.5, 31.4),
  _900: hsl(21.7, 77.8, 26.5),
  _950: hsl(20.9, 91.7, 14.1),
};
const yellow = {
  _50: hsl(54.5, 91.7, 95.3),
  _100: hsl(54.9, 96.7, 88),
  _200: hsl(52.8, 98.3, 76.9),
  _300: hsl(50.4, 97.8, 63.5),
  _400: hsl(47.9, 95.8, 53.1),
  _500: hsl(45.4, 93.4, 47.5),
  _600: hsl(40.6, 96.1, 40.4),
  _700: hsl(35.5, 91.7, 32.9),
  _800: hsl(31.8, 81, 28.8),
  _900: hsl(28.4, 72.5, 25.7),
  _950: hsl(26, 83.3, 14.1),
};
const green = {
  _50: hsl(138.5, 76.5, 96.7),
  _100: hsl(140.6, 84.2, 92.5),
  _200: hsl(141, 78.9, 85.1),
  _300: hsl(141.7, 76.6, 73.1),
  _400: hsl(141.9, 69.2, 58),
  _500: hsl(142.1, 70.6, 45.3),
  _600: hsl(142.1, 76.2, 36.3),
  _700: hsl(142.4, 71.8, 29.2),
  _800: hsl(142.8, 64.2, 24.1),
  _900: hsl(143.8, 61.2, 20.2),
  _950: hsl(144.9, 80.4, 10),
};
const emerald = {
  _50: hsl(151.8, 81, 95.9),
  _100: hsl(149.3, 80.4, 90),
  _200: hsl(152.4, 76, 80.4),
  _300: hsl(156.2, 71.6, 66.9),
  _400: hsl(158.1, 64.4, 51.6),
  _500: hsl(160.1, 84.1, 39.4),
  _600: hsl(161.4, 93.5, 30.4),
  _700: hsl(162.9, 93.5, 24.3),
  _800: hsl(163.1, 88.1, 19.8),
  _900: hsl(164.2, 85.7, 16.5),
  _950: hsl(165.7, 91.3, 9),
};
const teal = {
  _50: hsl(166.2, 76.5, 96.7),
  _100: hsl(167.2, 85.5, 89.2),
  _200: hsl(168.4, 83.8, 78.2),
  _300: hsl(170.6, 76.9, 64.3),
  _400: hsl(172.5, 66, 50.4),
  _500: hsl(173.4, 80.4, 40),
  _600: hsl(174.7, 83.9, 31.6),
  _700: hsl(175.3, 77.4, 26.1),
  _800: hsl(176.1, 69.4, 21.8),
  _900: hsl(175.9, 60.8, 19),
  _950: hsl(178.6, 84.3, 10),
};
const indigo = {
  _50: hsl(225.9, 100, 96.7),
  _100: hsl(226.5, 100, 93.9),
  _200: hsl(228, 96.5, 88.8),
  _300: hsl(229.7, 93.5, 81.8),
  _400: hsl(234.5, 89.5, 73.9),
  _500: hsl(238.7, 83.5, 66.7),
  _600: hsl(243.4, 75.4, 58.6),
  _700: hsl(244.5, 57.9, 50.6),
  _800: hsl(243.7, 54.5, 41.4),
  _900: hsl(242.2, 47.4, 34.3),
  _950: hsl(243.8, 47.1, 20),
};
const violet = {
  _50: hsl(250, 100, 97.6),
  _100: hsl(251.4, 91.3, 95.5),
  _200: hsl(250.5, 95.2, 91.8),
  _300: hsl(252.5, 94.7, 85.1),
  _400: hsl(255.1, 91.7, 76.3),
  _500: hsl(258.3, 89.5, 66.3),
  _600: hsl(262.1, 83.3, 57.8),
  _700: hsl(263.4, 70, 50.4),
  _800: hsl(263.4, 69.3, 42.2),
  _900: hsl(263.5, 67.4, 34.9),
  _950: hsl(261.2, 72.6, 22.9),
};
const purple = {
  _50: hsl(270, 100, 98),
  _100: hsl(268.7, 100, 95.5),
  _200: hsl(268.6, 100, 91.8),
  _300: hsl(269.2, 97.4, 85.1),
  _400: hsl(270, 95.2, 75.3),
  _500: hsl(270.7, 91, 65.1),
  _600: hsl(271.5, 81.3, 55.9),
  _700: hsl(272.1, 71.7, 47.1),
  _800: hsl(272.9, 67.2, 39.4),
  _900: hsl(273.6, 65.6, 32),
  _950: hsl(273.5, 86.9, 21),
};
const fuchsia = {
  _50: hsl(289.1, 100, 97.8),
  _100: hsl(287, 100, 95.5),
  _200: hsl(288.3, 95.8, 90.6),
  _300: hsl(291.1, 93.1, 82.9),
  _400: hsl(292, 91.4, 72.5),
  _500: hsl(292.2, 84.1, 60.6),
  _600: hsl(293.4, 69.5, 48.8),
  _700: hsl(294.7, 72.4, 39.8),
  _800: hsl(295.4, 70.2, 32.9),
  _900: hsl(296.7, 63.6, 28),
  _950: hsl(296.8, 90.2, 16.1),
};
const pink = {
  _50: hsl(327.3, 73.3, 97.1),
  _100: hsl(325.7, 77.8, 94.7),
  _200: hsl(325.9, 84.6, 89.8),
  _300: hsl(327.4, 87.1, 81.8),
  _400: hsl(328.6, 85.5, 70.2),
  _500: hsl(330.4, 81.2, 60.4),
  _600: hsl(333.3, 71.4, 50.6),
  _700: hsl(335.1, 77.6, 42),
  _800: hsl(335.8, 74.4, 35.3),
  _900: hsl(335.9, 69, 30.4),
  _950: hsl(336.2, 83.9, 17.1),
};
const rose = {
  _50: hsl(355.7, 100, 97.3),
  _100: hsl(355.6, 100, 94.7),
  _200: hsl(352.7, 96.1, 90),
  _300: hsl(352.6, 95.7, 81.8),
  _400: hsl(351.3, 94.5, 71.4),
  _500: hsl(349.7, 89.2, 60.2),
  _600: hsl(346.8, 77.2, 49.8),
  _700: hsl(345.3, 82.7, 40.8),
  _800: hsl(343.4, 79.7, 34.7),
  _900: hsl(341.5, 75.5, 30.4),
  _950: hsl(343.1, 87.7, 15.9),
};
export const colors = {
  lime,
  slate,
  zinc,
  gray,
  neutral,
  stone,
  cyan,
  sky,
  blue,
  red,
  orange,
  amber,
  yellow,
  green,
  emerald,
  teal,
  indigo,
  violet,
  purple,
  fuchsia,
  pink,
  rose,
} as const;
