'use client';
import { useMapTheme } from '../../context/map-context';
import { cn } from '@streetferret/utils';
import { Layer } from 'react-map-gl';

export function WaterwayLabel() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'waterway_label',
        type: 'symbol',
        source: 'openmaptiles',
        'source-layer': 'waterway',
        filter: ['all', ['has', 'name'], ['==', 'class', 'river']],
        layout: {
          'text-field': '{name}',
          'text-font': ['Americana-Bold'],
          'symbol-placement': 'line',
          'symbol-spacing': 300,
          'symbol-avoid-edges': false,
          'text-size': {
            stops: [
              [9, 8],
              [10, 9],
            ],
          },
          'text-padding': 2,
          'text-pitch-alignment': 'auto',
          'text-rotation-alignment': 'auto',
          'text-letter-spacing': 0,
          'text-keep-upright': true,
        },
        paint: {
          'text-color': cn({
            '#7a96a0': config.baseTheme === 'light',
            'rgba(164, 164, 164, 1)': config.baseTheme === 'dark',
          }),
          'text-halo-color': cn({
            '#f5f5f3': config.baseTheme === 'light',
            '#181818': config.baseTheme === 'dark',
          }),
          'text-halo-width': 1,
        },
      }}
    />
  );
}
