'use client';
import { Layer, type LayerProps } from 'react-map-gl';
import { useMapTheme } from '../../context/map-context';

// Create the ocean filter based on the ocean prop
const createOceanFilter = (ocean: boolean): LayerProps['filter'] => [
  'all',
  ['==', '$type', 'Polygon'],
  ocean ? ['==', 'class', 'ocean'] : ['!=', 'class', 'ocean'],
];

interface WaterLayersProps {
  ocean: boolean;
}

export function WaterLayers({ ocean }: WaterLayersProps) {
  const config = useMapTheme();

  const name = ocean ? 'ocean' : 'water';

  const commonLayerProps: LayerProps = {
    type: 'fill',
    source: 'openmaptiles',
    'source-layer': 'water',
    minzoom: 0,
    filter: createOceanFilter(ocean), // Apply the filter dynamically
    layout: {
      visibility: 'visible',
    },
  };

  return (
    <>
      <Layer
        id={`water_${name}`}
        {...commonLayerProps}
        paint={{
          'fill-color': config.theme.water,
          'fill-antialias': true,
          'fill-translate-anchor': 'map',
          'fill-opacity': 1,
        }}
      />
      <Layer
        id={`water_${name}_shadow`}
        {...commonLayerProps}
        paint={{
          'fill-color': 'transparent',
          'fill-antialias': true,
          'fill-translate-anchor': 'map',
          'fill-opacity': 1,
        }}
      />
    </>
  );
}
