'use client';
import { Layer, type LineLayer } from 'react-map-gl';
import { lineWidthScale } from '../../utils/layer-utils';
import { USER_TILES_SOURCE_ID } from './user-tiles-source';
import { useMemo } from 'react';
import { useMapTheme } from '../../context';

interface RouteLayersProps {
  hoveredRouteId: number | null;
  selectedRouteId?: number | null;
  hidden?: boolean;
  /**
   * if provided, only show for these routes
   */
  routeIncludeFilter?: string[];
  /**
   * if provided, exclude these routes
   */
  routeExcludeFilter?: string[];
}
export function RouteLayers({
  hoveredRouteId,
  selectedRouteId,
  hidden,
  routeIncludeFilter,
  routeExcludeFilter,
}: RouteLayersProps) {
  const { theme } = useMapTheme();

  const visibility = useMemo(() => (hidden ? 'none' : 'visible'), [hidden]);

  const filterShowRoute = useMemo(() => {
    return [
      'all',
      routeIncludeFilter?.length
        ? ['in', ['get', 'id'], ['literal', routeIncludeFilter]]
        : true,
      ['!', ['in', ['get', 'id'], ['literal', routeExcludeFilter ?? []]]],
    ];
  }, [routeIncludeFilter, routeExcludeFilter]);
  const routeBaseLayer: LineLayer = useMemo(() => {
    return {
      id: 'route-base',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'route',
      type: 'line',
      layout: {
        visibility,
      },
      paint: {
        'line-color': `hsl(24.6, 95%, 53.1%)`,
        'line-width': lineWidthScale(1.6),
      },
      filter: filterShowRoute,
    };
  }, [visibility, filterShowRoute]);
  const routeHoverLayer: LineLayer = useMemo(() => {
    return {
      id: 'route-hover',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'route',
      type: 'line',
      layout: {
        visibility,
      },
      paint: {
        'line-color': `hsl(24.6, 95%, 80.1%)`,
        'line-width': lineWidthScale(1.8),
      },
      filter: [
        'all',

        // filterShowRoute,
        ['==', 'id', hoveredRouteId ?? -1],
      ],
    };
  }, [hoveredRouteId, visibility, filterShowRoute]);
  const routeSelectedLayer: LineLayer = useMemo(() => {
    return {
      id: 'route-selected',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'route',
      type: 'line',
      paint: {
        'line-width': lineWidthScale(2.5),
        'line-color': theme.bounds,
      },
      layout: {
        visibility,
        'line-join': 'round',
        'line-cap': 'round',
      },
      filter: [
        'all',
        // filterShowRoute,
        ['==', 'id', selectedRouteId ?? -1],
      ],
    };
  }, [theme, selectedRouteId, visibility, filterShowRoute]);

  return (
    <>
      <Layer {...routeBaseLayer} beforeId="_road_overlays" />
      <Layer {...routeHoverLayer} beforeId="_road_overlays" />
      <Layer {...routeSelectedLayer} beforeId="_road_overlays" />
    </>
  );
}
