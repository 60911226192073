'use client';
import React, { useMemo } from 'react';
import { type MapColorTheme, type ResolvedMapColorTheme } from './map-theme';
import { DEFAULT_THEME } from './themes';

interface MapThemeContextProps {
  type: 'base' | 'satellite';
  theme: MapColorTheme;
  baseTheme: 'light' | 'dark';
}

export const MapContext = React.createContext<MapThemeContextProps>({
  type: 'base',
  baseTheme: 'light',
  theme: DEFAULT_THEME,
});

interface MapProviderProps extends Omit<MapThemeContextProps, 'theme'> {
  children: React.ReactNode;
  theme?: MapColorTheme;
}

export function MapProvider({
  children,
  theme,
  type,
  baseTheme,
}: MapProviderProps) {
  return (
    <MapContext.Provider
      value={{ theme: theme ?? DEFAULT_THEME, type, baseTheme }}>
      {children}
    </MapContext.Provider>
  );
}

/**
 * Hook to get the current map theme.
 */
export function useMapTheme() {
  const context = React.useContext(MapContext);
  if (context === undefined) {
    throw new Error('useMapTheme must be used within a MapThemeProvider');
  }
  const { theme, baseTheme, type } = context;
  const resolvedTheme = useMemo(
    () => resolveTheme(theme, baseTheme),
    [theme, baseTheme],
  );
  return {
    /**
     * The active map color theme.
     */
    theme: resolvedTheme,
    /**
     * The base color theme for the map.
     */
    baseTheme,
    type,
  };
}

function resolveTheme(
  theme: MapColorTheme,
  baseTheme: 'light' | 'dark',
): ResolvedMapColorTheme {
  return Object.fromEntries(
    Object.entries(theme).map(([key, value]) => {
      if (typeof value === 'object') {
        return [key, value[baseTheme]];
      }
      return [key, value];
    }),
  ) as ResolvedMapColorTheme;
}
