'use client';
import { Layer } from 'react-map-gl';
import { useMapTheme } from '../../context/map-context';

export function BoundaryState() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'boundary_state',
        type: 'line',
        source: 'openmaptiles',
        'source-layer': 'boundary',
        minzoom: 4,
        filter: ['all', ['==', 'admin_level', 4], ['==', 'maritime', 0]],
        paint: {
          'line-color': config.theme.bounds,
          // 'line-color': {
          //   stops: [
          //     [
          //       4,
          //       cn({
          //         '#ead5d7': config.baseTheme === 'light',
          //         'rgba(103, 103, 114, 1)': config.baseTheme === 'dark',
          //       }),
          //     ],
          //     [
          //       5,
          //       cn({
          //         '#ead5d7': config.baseTheme === 'light',
          //         'rgba(103, 103, 114, 1)': config.baseTheme === 'dark',
          //       }),
          //     ],
          //     [
          //       6,
          //       cn({
          //         '#e1c5c7': config.baseTheme === 'light',
          //         'rgba(103, 103, 114, 1)': config.baseTheme === 'dark',
          //       }),
          //     ],
          //   ],
          // },
          'line-width': {
            stops: [
              [4, 0.5],
              [7, 1],
              [8, 1],
              [9, 1.2],
            ],
          },
        },
      }}
    />
  );
}
