/**
 * Consistent line width scaling across zoom levels.
 * @param base base line width
 */
export function lineWidthScale(base: number) {
  return {
    base: base,
    stops: [
      [7, 0],
      [8, base - 0.5],
      [10, base + 0],
      [12, base + 0.5],
      [12.5, base + 1.0],
      [13, base + 1.5],
      [13.5, base + 2.0],
      [14, base + 3.5],
      [15, base + 5.5],
      [16, base + 7.5],
      [18, base + 8.5],
      [20, base + 12.5],
    ],
  };
}
