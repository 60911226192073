'use client';
import { useMapTheme } from '../../context/map-context';
import { Layer } from 'react-map-gl';

export function Building() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'building',
        type: 'fill',
        source: 'openmaptiles',
        'source-layer': 'building',
        layout: {
          visibility: config.type === 'satellite' ? 'none' : 'visible',
        },
        paint: {
          'fill-color': config.theme.building,
          'fill-antialias': true,
          'fill-opacity': {
            base: 1,
            stops: [
              [13, 0],
              [16, 1],
            ],
          },
        },
      }}
    />
  );
}
export function BuildingTop() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'building-top',
        type: 'fill',
        source: 'openmaptiles',
        'source-layer': 'building',
        layout: {
          visibility: config.type === 'satellite' ? 'none' : 'visible',
        },
        paint: {
          'fill-outline-color': config.theme.building_outline,
          'fill-color': config.theme.building,
          'fill-opacity': {
            base: 1,
            stops: [
              [13, 0],
              [16, 1],
            ],
          },
        },
      }}
    />
  );
}

export function BuildingLayers() {
  return (
    <>
      <Building />
      <BuildingTop />
    </>
  );
}
