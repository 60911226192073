'use client';
import { Layer } from 'react-map-gl';

interface PlaceholderProps {
  /**
   * Unique identifier for the layer. Prefix with _ by convention.
   */
  id: string;
}
/**
 * Empty placeholder layer to be used as a reference for other layers.
 */
export function Placeholder({ id }: PlaceholderProps) {
  return (
    <Layer
      {...{
        id,
        type: 'symbol',
        source: 'openmaptiles',
        'source-layer': 'place',
        layout: {
          visibility: 'none',
        },
      }}
    />
  );
}
