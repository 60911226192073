'use client';
import { Source } from 'react-map-gl';
import { TILESERVER_URL } from '../../constants';

export const USER_TILES_SOURCE_ID = 'user-tiles';
interface UserTilesSourceProps {
  children: React.ReactNode;
  stravaId: number;
  version: number;
}
export function UserTilesSource({
  children,
  stravaId,
  version,
}: UserTilesSourceProps) {
  return (
    <Source
      id={USER_TILES_SOURCE_ID}
      type="vector"
      url={`${TILESERVER_URL}/user/${stravaId}/${version}/style.json`}>
      {children}
    </Source>
  );
}
