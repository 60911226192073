'use client';

import { type ActivityType } from '@streetferret/api';
import { useMemo } from 'react';
import { Layer } from 'react-map-gl';
import { useMapTheme } from '../../context/map-context';
import { lineWidthScale } from '../../utils/layer-utils';
import { USER_TILES_SOURCE_ID } from './user-tiles-source';

export interface PeaksLayersProps {
  selectedPeakIds?: string[] | null;
  hoveredPeakIds?: string[] | null;
  /**
   * if provided, only show progress for these cities.
   */
  peakIncludeFilter?: number[];
  /**
   * if provided, exclude these cities.
   */
  peakExcludeFilter?: number[];
  hidden?: boolean;
}
export function PeaksLayers({ hidden }: PeaksLayersProps) {
  const { theme } = useMapTheme();
  const visibility = useMemo(() => (hidden ? 'none' : 'visible'), [hidden]);

  return (
    <>
      <Layer
        id="peaks-base-layer"
        source={USER_TILES_SOURCE_ID}
        source-layer="peaks"
        type="circle"
        paint={{
          'circle-radius': 5,
          'circle-color': theme.complete,
        }}
        layout={{
          visibility,
        }}
      />
    </>
  );
}
