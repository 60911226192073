'use client';
import { useMapTheme } from '../../context/map-context';
import { cn } from '@streetferret/utils';
import { Layer } from 'react-map-gl';

export function PlaceLayers() {
  const config = useMapTheme();
  return (
    <>
      <Layer
        {...{
          id: 'place_hamlet',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'place',
          minzoom: 12,
          maxzoom: 16,
          filter: [
            'any',
            ['==', 'class', 'neighbourhood'],
            ['==', 'class', 'hamlet'],
          ],
          layout: {
            'text-field': '{name}',
            'text-font': ['Americana-Bold'],
            'text-size': {
              stops: [
                [13, 8],
                [14, 10],
                [16, 11],
              ],
            },
            'icon-image': '',
            'icon-offset': [16, 0],
            'text-anchor': 'center',
            'icon-size': 1,
            'text-max-width': 10,
            'text-keep-upright': true,
            'text-offset': [0.2, 0.2],
            'text-transform': {
              stops: [
                [12, 'none'],
                [14, 'uppercase'],
              ],
            },
          },
          paint: {
            'text-color': cn({
              '#697b89': config.baseTheme === 'light',
              'rgba(182, 180, 180, 1)': config.baseTheme === 'dark',
            }),
            'icon-color': cn({
              '#697b89': config.baseTheme === 'light',
              '#666': config.baseTheme === 'dark',
            }),
            'icon-translate-anchor': 'map',
            'text-halo-color': cn({
              'rgba(255,255,255,0.8)': config.baseTheme === 'light',
              'rgba(53, 52, 52, 1)': config.baseTheme === 'dark',
            }),
            'text-halo-width': config.baseTheme === 'light' ? 2 : 1,
          },
        }}
      />
      <Layer
        {...{
          id: 'place_suburbs',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'place',
          minzoom: 9,
          maxzoom: 16,
          filter: ['all', ['==', 'class', 'suburb']],
          layout: {
            'text-field': '{name}',
            'text-font': ['Americana-Bold'],
            'text-size': {
              stops: [
                [12, 9],
                [13, 10],
                [14, 11],
                [15, 12],
                [16, 13],
              ],
            },
            'icon-image': '',
            'icon-offset': [16, 0],
            'text-anchor': 'center',
            'icon-size': 1,
            'text-max-width': 10,
            'text-keep-upright': true,
            'text-offset': [0.2, 0.2],
            'text-transform': {
              stops: [
                [8, 'none'],
                [12, 'uppercase'],
              ],
            },
          },
          paint: {
            'text-color': cn(
              config.type === 'satellite' && '#fff',
              config.type === 'base' && {
                '#697b89': config.baseTheme === 'light',
                '#666': config.baseTheme === 'dark',
              },
            ),
            'icon-color': cn({
              '#697b89': config.baseTheme === 'light',
              '#666': config.baseTheme === 'dark',
            }),
            'icon-translate-anchor': 'map',
            'text-halo-color': cn(
              config.type === 'satellite' && '#222',
              config.type === 'base' && {
                'rgba(255,255,255,0.8)': config.baseTheme === 'light',
                '#222': config.baseTheme === 'dark',
              },
            ),
            'text-halo-width': 2,
          },
        }}
      />
      <Layer
        {...{
          id: 'place_villages',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'place',
          minzoom: 9,
          maxzoom: 16,
          filter: ['all', ['==', 'class', 'village']],
          layout: {
            'text-field': '{name}',
            'text-font': ['Americana-Bold'],
            'text-size': {
              stops: [
                [10, 9],
                [12, 10],
                [13, 11],
                [14, 12],
                [16, 13],
              ],
            },
            'icon-image': '',
            'icon-offset': [16, 0],
            'text-anchor': 'center',
            'icon-size': 1,
            'text-max-width': 10,
            'text-keep-upright': true,
            'text-offset': [0.2, 0.2],
            'text-transform': 'none',
          },
          paint: {
            'text-color': cn(
              config.type === 'satellite' && '#fff',
              config.type === 'base' && {
                '#697b89': config.baseTheme === 'light',
                'hsla(0, 0.49%, 70%, 1)': config.baseTheme === 'dark',
              },
            ),
            'icon-color': cn({
              '#697b89': config.baseTheme === 'light',
              '#666': config.baseTheme === 'dark',
            }),
            'icon-translate-anchor': 'map',
            'text-halo-color': cn(
              config.type === 'satellite' && '#222',
              config.type === 'base' && {
                'rgba(255,255,255,0.8)': config.baseTheme === 'light',
                '#222': config.baseTheme === 'dark',
              },
            ),
            'text-halo-width': 2,
          },
        }}
      />
      <Layer
        {...{
          id: 'place_town',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'place',
          minzoom: 8,
          maxzoom: 14,
          filter: ['all', ['==', 'class', 'town']],
          layout: {
            'text-field': '{name}',
            'text-font': ['Americana-Bold'],
            'text-size': {
              stops: [
                [8, 10],
                [9, 10],
                [10, 11],
                [13, 14],
                [14, 15],
              ],
            },
            'icon-image': '',
            'icon-offset': [16, 0],
            'text-anchor': 'center',
            'icon-size': 1,
            'text-max-width': 10,
            'text-keep-upright': true,
            'text-offset': [0.2, 0.2],
            'text-transform': 'none',
          },
          paint: {
            'text-color': cn(
              config.type === 'satellite' && '#fff',
              config.type === 'base' && {
                '#697b89': config.baseTheme === 'light',
                'rgba(204, 208, 228, 1)': config.baseTheme === 'dark',
              },
            ),
            'icon-color': cn({
              '#697b89': config.baseTheme === 'light',
              '#666': config.baseTheme === 'dark',
            }),
            'icon-translate-anchor': 'map',
            'text-halo-color': cn(
              config.type === 'satellite' && '#222',
              config.type === 'base' && {
                'rgba(255,255,255,0.8)': config.baseTheme === 'light',
                '#222': config.baseTheme === 'dark',
              },
            ),
            'text-halo-width': 2,
          },
        }}
      />
      <Layer
        {...{
          id: 'place_country_2',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'place',
          minzoom: 3,
          maxzoom: 10,
          filter: [
            'all',
            ['==', 'class', 'country'],
            ['>=', 'rank', 3],
            ['has', 'iso_a2'],
          ],
          layout: {
            'text-field': '{name}',
            'text-font': ['Americana-Bold'],
            'text-size': {
              stops: [
                [3, 10],
                [5, 11],
                [6, 12],
                [7, 13],
                [8, 14],
              ],
            },
            'text-transform': 'uppercase',
          },
          paint: {
            'text-color': {
              stops: [
                [
                  3,
                  cn({
                    '#8a99a4': config.baseTheme === 'light',
                    'rgba(157, 157, 157, 1)': config.baseTheme === 'dark',
                  }),
                ],
                [
                  5,
                  cn({
                    '#a1adb6': config.baseTheme === 'light',
                    'rgba(114, 114, 114, 1)': config.baseTheme === 'dark',
                  }),
                ],
                [
                  6,
                  cn({
                    '#b9c2c9': config.baseTheme === 'light',
                    'rgba(112, 112, 112, 1)': config.baseTheme === 'dark',
                  }),
                ],
              ],
            },
            'text-halo-color': cn({
              '#fafaf8': config.baseTheme === 'light',
              '#111': config.baseTheme === 'dark',
            }),
            'text-halo-width': 1,
          },
        }}
      />
      <Layer
        {...{
          id: 'place_country_1',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'place',
          minzoom: 2,
          maxzoom: 7,
          filter: ['all', ['==', 'class', 'country'], ['<=', 'rank', 2]],
          layout: {
            'text-field': '{name}',
            'text-font': ['Americana-Bold'],
            'text-size': {
              stops: [
                [3, 11],
                [4, 12],
                [5, 13],
                [6, 14],
              ],
            },
            'text-transform': 'uppercase',
            'text-max-width': {
              stops: [
                [2, 6],
                [3, 6],
                [4, 9],
                [5, 12],
              ],
            },
          },
          paint: {
            'text-color': {
              stops: [
                [
                  3,
                  cn({
                    '#8a99a4': config.baseTheme === 'light',
                    'rgba(158, 182, 189, 1)': config.baseTheme === 'dark',
                  }),
                ],
                [
                  5,
                  cn({
                    '#a1adb6': config.baseTheme === 'light',
                    'rgba(118, 126, 137, 1)': config.baseTheme === 'dark',
                  }),
                ],
                [
                  6,
                  cn({
                    '#b9c2c9': config.baseTheme === 'light',
                    'rgba(120, 141, 147, 1)': config.baseTheme === 'dark',
                  }),
                ],
              ],
            },
            'text-halo-color': cn({
              '#fafaf8': config.baseTheme === 'light',
              '#111': config.baseTheme === 'dark',
            }),
            'text-halo-width': 1,
          },
        }}
      />
      <Layer
        {...{
          id: 'place_state',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'place',
          minzoom: 6,
          maxzoom: 10,
          filter: ['all', ['==', 'class', 'state'], ['<=', 'rank', 4]],
          layout: {
            'text-field': '{name}',
            'text-font': ['Americana-Bold'],
            'text-size': {
              stops: [
                [5, 12],
                [7, 14],
              ],
            },
            'text-transform': 'uppercase',
            'text-max-width': 9,
          },
          paint: {
            'text-color': cn({
              '#97a4ae': config.baseTheme === 'light',
              'rgba(203, 230, 230, 1)': config.baseTheme === 'dark',
            }),
            'text-halo-color': cn({
              '#fafaf8': config.baseTheme === 'light',
              '#111': config.baseTheme === 'dark',
            }),
            'text-halo-width': 0,
          },
        }}
      />
      <Layer
        {...{
          id: 'place_continent',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'place',
          minzoom: 0,
          maxzoom: 2,
          filter: ['all', ['==', 'class', 'continent']],
          layout: {
            'text-field': '{name}',
            'text-font': ['Americana-Bold'],
            'text-transform': 'uppercase',
            'text-size': 14,
            'text-letter-spacing': 0.1,
            'text-max-width': 9,
            'text-justify': 'center',
            'text-keep-upright': false,
          },
          paint: {
            'text-color': cn({
              '#697b89': config.baseTheme === 'light',
              'rgba(135, 164, 179, 1)': config.baseTheme === 'dark',
            }),
            'text-halo-color': cn({
              '#fafaf8': config.baseTheme === 'light',
              '#111': config.baseTheme === 'dark',
            }),
            'text-halo-width': 1,
          },
        }}
      />
      <Layer
        {...{
          id: 'place_city_r6',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'place',
          minzoom: 9,
          maxzoom: 15,
          filter: ['all', ['==', 'class', 'city'], ['>=', 'rank', 6]],
          layout: {
            'text-field': '{name}',
            'text-font': ['Americana-Bold'],
            'text-size': {
              stops: [
                [8, 12],
                [9, 13],
                [10, 14],
                [13, 17],
                [14, 20],
              ],
            },
            'icon-image': '',
            'icon-offset': [16, 0],
            'text-anchor': 'center',
            'icon-size': 1,
            'text-max-width': 10,
            'text-keep-upright': true,
            'text-offset': [0.2, 0.2],
            'text-transform': 'uppercase',
          },
          paint: {
            'text-color': cn(
              config.type === 'satellite' && '#fff',
              config.type === 'base' && {
                '#697b89': config.baseTheme === 'light',
                'rgba(168, 176, 180, 1)': config.baseTheme === 'dark',
              },
            ),
            'icon-color': cn({
              '#697b89': config.baseTheme === 'light',
              '#666': config.baseTheme === 'dark',
            }),
            'icon-translate-anchor': 'map',
            'text-halo-color': cn(
              config.type === 'satellite' && '#222',
              config.type === 'base' && {
                'rgba(255,255,255,0.8)': config.baseTheme === 'light',
                '#222': config.baseTheme === 'dark',
              },
            ),
            'text-halo-width': 2,
          },
        }}
      />
      <Layer
        {...{
          id: 'place_city_r5',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'place',
          minzoom: 9,
          maxzoom: 15,
          filter: [
            'all',
            ['==', 'class', 'city'],
            ['>=', 'rank', 0],
            ['<=', 'rank', 5],
          ],
          layout: {
            'text-field': '{name}',
            'text-font': ['Americana-Bold'],
            'text-size': {
              stops: [
                [8, 14],
                [10, 16],
                [13, 19],
                [14, 22],
              ],
            },
            'icon-image': '',
            'icon-offset': [16, 0],
            'text-anchor': 'center',
            'icon-size': 1,
            'text-max-width': 10,
            'text-keep-upright': true,
            'text-offset': [0.2, 0.2],
            'text-transform': 'uppercase',
          },
          paint: {
            'text-color': cn(
              config.type === 'satellite' && '#fff',
              config.type === 'base' && {
                '#697b89': config.baseTheme === 'light',
                'rgba(211, 228, 236, 1)': config.baseTheme === 'dark',
              },
            ),
            'icon-color': cn({
              '#697b89': config.baseTheme === 'light',
              '#666': config.baseTheme === 'dark',
            }),
            'icon-translate-anchor': 'map',
            'text-halo-color': cn(
              config.type === 'satellite' && '#222',
              config.type === 'base' && {
                'rgba(255,255,255,0.8)': config.baseTheme === 'light',
                '#222': config.baseTheme === 'dark',
              },
            ),
            'text-halo-width': 2,
          },
        }}
      />
      <Layer
        {...{
          id: 'place_city_dot_z7',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'place',
          minzoom: 7,
          maxzoom: 8,
          filter: [
            'all',
            ['!has', 'capital'],
            ['!in', 'class', 'country', 'state'],
          ],
          layout: {
            'text-field': '{name}',
            'text-font': ['Americana-Bold'],
            'text-size': 12,
            'icon-image': 'circle-11',
            'icon-offset': [16, 5],
            'text-anchor': 'right',
            'icon-size': 0.4,
            'text-max-width': 8,
            'text-keep-upright': true,
            'text-offset': [0.2, 0.2],
          },
          paint: {
            'text-color': cn({
              '#697b89': config.baseTheme === 'light',
              'rgba(160, 179, 191, 1)': config.baseTheme === 'dark',
            }),
            'icon-color': cn({
              '#697b89': config.baseTheme === 'light',
              'rgba(113, 128, 147, 1)': config.baseTheme === 'dark',
            }),
            'icon-translate-anchor': 'map',
            'text-halo-color': cn({
              'rgba(255,255,255,0.8)': config.baseTheme === 'light',
              '#222': config.baseTheme === 'dark',
            }),
            'text-halo-width': 2,
          },
        }}
      />
      <Layer
        {...{
          id: 'place_capital_dot_z7',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'place',
          minzoom: 7,
          maxzoom: 8,
          filter: ['all', ['>', 'capital', 0]],
          layout: {
            'text-field': '{name}',
            'text-font': ['Americana-Bold'],
            'text-size': 12,
            'icon-image': 'circle-11',
            'icon-offset': [16, 5],
            'text-anchor': 'right',
            'icon-size': 0.4,
            'text-max-width': 8,
            'text-keep-upright': true,
            'text-offset': [0.2, 0.2],
            'text-transform': 'uppercase',
          },
          paint: {
            'text-color': cn({
              '#697b89': config.baseTheme === 'light',
              'rgba(177, 201, 214, 1)': config.baseTheme === 'dark',
            }),
            'icon-color': cn({
              '#697b89': config.baseTheme === 'light',
              '#666': config.baseTheme === 'dark',
            }),
            'icon-translate-anchor': 'map',
            'text-halo-color': cn({
              'rgba(255,255,255,0.8)': config.baseTheme === 'light',
              '#222': config.baseTheme === 'dark',
            }),
            'text-halo-width': 2,
          },
        }}
      />
    </>
  );
}
