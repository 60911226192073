'use client';
import { useMapTheme } from '../../context/map-context';
import { cn } from '@streetferret/utils';
import { Layer } from 'react-map-gl';

export function TunnelLayers() {
  const config = useMapTheme();
  return (
    <>
      <Layer
        {...{
          id: 'tunnel_service_case',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 15,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'service'],
            ['==', 'brunnel', 'tunnel'],
          ],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [15, 1],
                [16, 3],
                [17, 6],
                [18, 8],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#ddd': config.baseTheme === 'light',
              '#1a1a1a': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'tunnel_minor_case',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 13,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'minor'],
            ['==', 'brunnel', 'tunnel'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'miter',
          },
          paint: {
            'line-width': {
              stops: [
                [11, 0.5],
                [12, 0.5],
                [14, 2],
                [15, 4],
                [16, 6],
                [17, 10],
                [18, 14],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#ddd': config.baseTheme === 'light',
              '#1a1a1a': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'tunnel_sec_case',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 11,
          maxzoom: 24,
          filter: [
            'all',
            ['in', 'class', 'secondary', 'tertiary'],
            ['==', 'brunnel', 'tunnel'],
          ],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [11, 0.5],
                [12, 1],
                [13, 2],
                [14, 5],
                [15, 6],
                [16, 8],
                [17, 12],
                [18, 16],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#ddd': config.baseTheme === 'light',
              '#1a1a1a': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'tunnel_pri_case',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 8,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'primary'],
            ['!=', 'ramp', 1],
            ['==', 'brunnel', 'tunnel'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [6, 0.5],
                [7, 0.8],
                [8, 1],
                [11, 3],
                [13, 4],
                [14, 6],
                [15, 8],
                [16, 10],
                [17, 14],
                [18, 18],
              ],
            },
            'line-opacity': {
              stops: [
                [5, 0.5],
                [7, 1],
              ],
            },
            'line-color': cn({
              '#ddd': config.baseTheme === 'light',
              '#1a1a1a': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'tunnel_trunk_case',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 5,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'trunk'],
            ['!=', 'ramp', 1],
            ['==', 'brunnel', 'tunnel'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
            visibility: 'visible',
          },
          paint: {
            'line-width': {
              stops: [
                [6, 0.5],
                [7, 0.8],
                [8, 1],
                [11, 3],
                [13, 4],
                [14, 6],
                [15, 8],
                [16, 10],
                [17, 14],
                [18, 18],
              ],
            },
            'line-opacity': {
              stops: [
                [5, 0.5],
                [7, 1],
              ],
            },
            'line-color': cn({
              '#ddd': config.baseTheme === 'light',
              '#232323': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'tunnel_mot_case',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 5,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'motorway'],
            ['!=', 'ramp', 1],
            ['==', 'brunnel', 'tunnel'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [6, 0.5],
                [7, 0.8],
                [8, 1],
                [11, 3],
                [12, 4],
                [13, 5],
                [14, 7],
                [15, 9],
                [16, 11],
                [17, 13],
                [18, 22],
              ],
            },
            'line-opacity': {
              stops: [
                [6, 0.5],
                [7, 1],
              ],
            },
            'line-color': cn({
              '#ddd': config.baseTheme === 'light',
              '#232323': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'tunnel_minor_fill',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 15,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'minor'],
            ['==', 'brunnel', 'tunnel'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [15, 3],
                [16, 4],
                [17, 8],
                [18, 12],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              'rgba(238, 238, 238, 1)': config.baseTheme === 'light',
              'rgba(22, 22, 22, 1)': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'tunnel_sec_fill',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 13,
          maxzoom: 24,
          filter: [
            'all',
            ['in', 'class', 'secondary', 'tertiary'],
            ['==', 'brunnel', 'tunnel'],
          ],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [11, 2],
                [13, 2],
                [14, 3],
                [15, 4],
                [16, 6],
                [17, 10],
                [18, 14],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#eee': config.baseTheme === 'light',
              '#161616': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'tunnel_pri_fill',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 11,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'primary'],
            ['!=', 'ramp', 1],
            ['==', 'brunnel', 'tunnel'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [11, 1],
                [13, 2],
                [14, 4],
                [15, 6],
                [16, 8],
                [17, 12],
                [18, 16],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#eee': config.baseTheme === 'light',
              'rgba(65, 71, 88, 1)': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'tunnel_trunk_fill',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 11,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'trunk'],
            ['!=', 'ramp', 1],
            ['==', 'brunnel', 'tunnel'],
          ],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
            visibility: 'visible',
          },
          paint: {
            'line-width': {
              stops: [
                [11, 1],
                [13, 2],
                [14, 4],
                [15, 6],
                [16, 8],
                [17, 12],
                [18, 16],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#eee': config.baseTheme === 'light',
              '#161616': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'tunnel_mot_fill',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 10,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'motorway'],
            ['!=', 'ramp', 1],
            ['==', 'brunnel', 'tunnel'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [10, 1],
                [12, 2],
                [13, 3],
                [14, 5],
                [15, 7],
                [16, 9],
                [17, 11],
                [18, 20],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#eee': config.baseTheme === 'light',
              'rgba(65, 71, 88, 1)': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'tunnel_rail',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 13,
          filter: ['all', ['==', 'class', 'rail'], ['==', 'brunnel', 'tunnel']],
          layout: {
            visibility: 'visible',
            'line-join': 'round',
          },
          paint: {
            'line-color': cn({
              '#dddddd': config.baseTheme === 'light',
              '#1a1a1a': config.baseTheme === 'dark',
            }),
            'line-width': {
              base: 1.3,
              stops: [
                [13, 0.5],
                [14, 1],
                [15, 1],
                [16, 3],
                [21, 7],
              ],
            },
            'line-opacity': 0.5,
          },
        }}
      />
      <Layer
        {...{
          id: 'tunnel_rail_dash',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 15,
          filter: ['all', ['==', 'class', 'rail'], ['==', 'brunnel', 'tunnel']],
          layout: {
            visibility: 'visible',
            'line-join': 'round',
          },
          paint: {
            'line-color': cn({
              '#ffffff': config.baseTheme === 'light',
              '#111': config.baseTheme === 'dark',
            }),
            'line-width': {
              base: 1.3,
              stops: [
                [15, 0.5],
                [16, 1],
                [20, 5],
              ],
            },
            'line-opacity': 0.5,
          },
        }}
      />
    </>
  );
}
