'use client';
import { useMapTheme } from '../../context/map-context';
import { cn } from '@streetferret/utils';
import { Layer } from 'react-map-gl';

export function RoadnameMinor() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'roadname_minor',
        type: 'symbol',
        source: 'openmaptiles',
        'source-layer': 'transportation_name',
        filter: ['all', ['in', 'class', 'minor', 'service']],
        layout: {
          'symbol-placement': 'line',
          'text-font': ['Americana-Bold'],
          'text-size': {
            stops: [
              [13, 10],
              [14, 11],
              [15, 12],
              [17, 13],
            ],
          },
          'text-field': '{name}',
          'symbol-avoid-edges': false,
          'symbol-spacing': 200,
          'text-pitch-alignment': 'auto',
          'text-rotation-alignment': 'auto',
          'text-justify': 'center',
          'text-anchor': 'center',
        },
        paint: {
          'text-color': cn(
            config.type === 'satellite' && '#fff',
            config.type === 'base' && config.theme.roadText,
          ),
          'text-halo-color': cn(
            config.type === 'satellite' && '#222',
            config.type === 'base' && config.theme.roadHalo,
          ),
          'text-halo-width': 1,
        },
      }}
    />
  );
}

export function RoadnameSec() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'roadname_sec',
        type: 'symbol',
        source: 'openmaptiles',
        'source-layer': 'transportation_name',
        filter: ['all', ['in', 'class', 'secondary', 'tertiary']],
        layout: {
          'symbol-placement': 'line-center',
          'text-font': ['Americana-Bold'],
          'text-size': {
            stops: [
              [15, 9],
              [16, 11],
              [18, 12],
            ],
          },
          'text-field': '{name}',
          'symbol-avoid-edges': false,
          'symbol-spacing': 200,
          'text-pitch-alignment': 'auto',
          'text-rotation-alignment': 'auto',
          'text-justify': 'center',
          'text-anchor': 'center',
        },
        paint: {
          'text-color': cn(
            config.type === 'satellite' && config.theme.roadText,
            config.type === 'base' && config.theme.roadText,
          ),
          'text-halo-color': cn(
            config.type === 'satellite' && '#222',
            config.type === 'base' && config.theme.roadHalo,
          ),
          'text-halo-width': 1,
        },
      }}
    />
  );
}
export function RoadnamePri() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'roadname_pri',
        type: 'symbol',
        source: 'openmaptiles',
        'source-layer': 'transportation_name',
        // minzoom: 14,
        filter: ['all', ['in', 'class', 'primary']],
        layout: {
          'symbol-placement': 'line',
          'text-font': ['Americana-Bold'],
          'text-size': {
            stops: [
              [13, 10],
              [14, 11],
              [15, 12],
              [16, 13],
              [18, 14],
            ],
          },
          'text-field': '{name}',
          'symbol-avoid-edges': false,
          'text-pitch-alignment': 'auto',
          'text-rotation-alignment': 'auto',
          'text-justify': 'center',
          'text-anchor': 'center',
        },
        paint: {
          'text-color': cn(
            config.type === 'satellite' && config.theme.roadText,
            config.type === 'base' && config.theme.roadText,
          ),
          'text-halo-color': cn(
            config.type === 'satellite' && config.theme.roadText,
            config.type === 'base' && config.theme.roadHalo,
          ),
          'text-halo-width': 1,
        },
      }}
    />
  );
}
export function RoadnameMajor() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'roadname_major',
        type: 'symbol',
        source: 'openmaptiles',
        'source-layer': 'transportation_name',
        filter: ['all', ['in', 'class', 'trunk', 'motorway']],
        layout: {
          'symbol-placement': 'line',
          'text-font': ['Americana-Bold'],
          'text-size': {
            stops: [
              [13, 9],
              [14, 10],
              [15, 10],
              [16, 11],
              [18, 12],
            ],
          },
          'text-field': '{name}',
          'symbol-avoid-edges': false,
          'text-pitch-alignment': 'auto',
          'text-rotation-alignment': 'auto',
          'text-justify': 'center',
          'text-anchor': 'center',
        },
        paint: {
          'text-color': cn(
            config.type === 'satellite' && config.theme.roadText,
            config.type === 'base' && config.theme.roadText,
          ),
          'text-halo-color': cn(
            config.type === 'satellite' && config.theme.roadText,
            config.type === 'base' && config.theme.roadHalo,
          ),
          'text-halo-width': 1,
        },
      }}
    />
  );
}
