'use client';
import { useMapTheme } from '../../context/map-context';
import { cn } from '@streetferret/utils';
import { Layer } from 'react-map-gl';

export function WaternameLayers() {
  const config = useMapTheme();
  return (
    <>
      <Layer
        {...{
          id: 'watername_ocean',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'water_name',
          minzoom: 0,
          maxzoom: 5,
          filter: [
            'all',
            ['has', 'name'],
            ['==', '$type', 'Point'],
            ['==', 'class', 'ocean'],
          ],
          layout: {
            'text-field': '{name}',
            'symbol-placement': 'point',
            'text-size': {
              stops: [
                [0, 13],
                [2, 14],
                [4, 18],
              ],
            },
            'text-font': ['Americana-Bold'],
            'text-line-height': 1.2,
            'text-padding': 2,
            'text-allow-overlap': false,
            'text-ignore-placement': false,
            'text-pitch-alignment': 'auto',
            'text-rotation-alignment': 'auto',
            'text-max-width': 6,
            'text-letter-spacing': 0.1,
          },
          paint: {
            'text-color': cn({
              '#abb6be': config.baseTheme === 'light',
              'rgba(109, 123, 129, 1)': config.baseTheme === 'dark',
            }),
            'text-halo-color': cn({
              '#d4dadc': config.baseTheme === 'light',
              'rgba(0,0,0,0.7)': config.baseTheme === 'dark',
            }),
            'text-halo-width': 1,
            'text-halo-blur': 0,
          },
        }}
      />
      <Layer
        {...{
          id: 'watername_sea',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'water_name',
          minzoom: 5,
          filter: [
            'all',
            ['has', 'name'],
            ['==', '$type', 'Point'],
            ['==', 'class', 'sea'],
          ],
          layout: {
            'text-field': '{name}',
            'symbol-placement': 'point',
            'text-size': 12,
            'text-font': ['Americana-Bold'],
            'text-line-height': 1.2,
            'text-padding': 2,
            'text-allow-overlap': false,
            'text-ignore-placement': false,
            'text-pitch-alignment': 'auto',
            'text-rotation-alignment': 'auto',
            'text-max-width': 6,
            'text-letter-spacing': 0.1,
          },
          paint: {
            'text-color': cn({
              '#abb6be': config.baseTheme === 'light',
              'rgba(109, 123, 129, 1)': config.baseTheme === 'dark',
            }),
            'text-halo-color': cn({
              '#d4dadc': config.baseTheme === 'light',
              'rgba(0,0,0,0.7)': config.baseTheme === 'dark',
            }),
            'text-halo-width': 1,
            'text-halo-blur': 0,
          },
        }}
      />
      <Layer
        {...{
          id: 'watername_lake',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'water_name',
          minzoom: 4,
          filter: [
            'all',
            ['has', 'name'],
            ['==', '$type', 'Point'],
            ['==', 'class', 'lake'],
          ],
          layout: {
            'text-field': '{name}',
            'symbol-placement': 'point',
            'text-size': {
              stops: [
                [13, 9],
                [14, 10],
                [15, 11],
                [16, 12],
                [17, 13],
              ],
            },
            'text-font': ['Americana-Bold'],
            'text-line-height': 1.2,
            'text-padding': 2,
            'text-allow-overlap': false,
            'text-ignore-placement': false,
            'text-pitch-alignment': 'auto',
            'text-rotation-alignment': 'auto',
          },
          paint: {
            'text-color': cn({
              '#7a96a0': config.baseTheme === 'light',
              'rgba(155, 155, 155, 1)': config.baseTheme === 'dark',
            }),
            'text-halo-color': cn({
              '#f5f5f3': config.baseTheme === 'light',
              '#181818': config.baseTheme === 'dark',
            }),
            'text-halo-width': 1,
            'text-halo-blur': 1,
          },
        }}
      />
      <Layer
        {...{
          id: 'watername_lake_line',
          type: 'symbol',
          source: 'openmaptiles',
          'source-layer': 'water_name',
          filter: ['all', ['has', 'name'], ['==', '$type', 'LineString']],
          layout: {
            'text-field': '{name}',
            'symbol-placement': 'line',
            'text-size': {
              stops: [
                [13, 9],
                [14, 10],
                [15, 11],
                [16, 12],
                [17, 13],
              ],
            },
            'text-font': ['Americana-Bold'],
            'symbol-spacing': 350,
            'text-pitch-alignment': 'auto',
            'text-rotation-alignment': 'auto',
            'text-line-height': 1.2,
          },
          paint: {
            'text-color': cn({
              '#7a96a0': config.baseTheme === 'light',
              '#444': config.baseTheme === 'dark',
            }),
            'text-halo-color': cn({
              '#f5f5f3': config.baseTheme === 'light',
              '#181818': config.baseTheme === 'dark',
            }),
            'text-halo-width': 1,
            'text-halo-blur': 1,
          },
        }}
      />
    </>
  );
}
