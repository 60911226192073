'use client';
import { Source } from 'react-map-gl';
import { SATELLITE_TILE_URL } from '../../constants';

export const SATELLITE_SOURCE_ID = 'blue-marble';
interface SatelliteSourceProps {
  children: React.ReactNode;
}
export function SatelliteSource({ children }: SatelliteSourceProps) {
  return (
    <Source
      id={SATELLITE_SOURCE_ID}
      type="raster"
      maxzoom={18.5}
      tiles={[SATELLITE_TILE_URL]}
      tileSize={256}>
      {children}
    </Source>
  );
}
