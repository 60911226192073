'use client';
import { useMapTheme } from '../../context/map-context';
import { Layer } from 'react-map-gl';

// TODO: do we need this layer?
export function AerowayRunway() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'aeroway-runway',
        type: 'line',
        source: 'openmaptiles',
        'source-layer': 'aeroway',
        minzoom: 12,
        filter: ['all', ['==', 'class', 'runway']],
        layout: {
          'line-cap': 'square',
          visibility: config.type === 'satellite' ? 'none' : 'visible',
        },
        paint: {
          'line-width': {
            stops: [
              [11, 1],
              [13, 4],
              [14, 6],
              [15, 8],
              [16, 10],
            ],
          },
          'line-color': config.theme.road_exclude,
        },
      }}
    />
  );
}
export function AerowayTaxiway() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'aeroway-taxiway',
        type: 'line',
        source: 'openmaptiles',
        'source-layer': 'aeroway',
        minzoom: 13,
        filter: ['all', ['==', 'class', 'taxiway']],
        layout: {
          visibility: config.type === 'satellite' ? 'none' : 'visible',
        },
        paint: {
          'line-color': config.theme.road_exclude,
          'line-width': {
            stops: [
              [13, 0.5],
              [14, 1],
              [15, 2],
              [16, 4],
            ],
          },
        },
      }}
    />
  );
}
export function AerowayLayers() {
  return (
    <>
      <AerowayRunway />
      <AerowayTaxiway />
    </>
  );
}
