'use client';
import { useEffect, useState } from 'react';

export const CountdownClock = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = Math.abs(+new Date(targetDate) - +new Date());
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        mins: Math.floor((difference / 1000 / 60) % 60),
        secs: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  if (!mounted) return null;
  return (
    <div className="flex items-center gap-2.5 justify-center">
      {Object.entries(timeLeft).map(([unit, value]) => (
        <div key={unit}>
          <div className="font-bold tabular-nums leading-3 tracking-wider">
            {value as number}
          </div>
          <div className="text-xs">{unit}</div>
        </div>
      ))}
    </div>
  );
};

export const CountdownClockInline = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = Math.abs(+new Date(targetDate) - +new Date());
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        mins: Math.floor((difference / 1000 / 60) % 60),
        secs: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  if (!mounted) return null;
  return (
    <div className="flex items-center gap-1">
      {Object.entries(timeLeft).map(([unit, value]) => (
        <div key={unit} className="space-x-0.5">
          <span className="font-bold tabular-nums leading-3 tracking-wider">
            {value as number}
          </span>
          <span className="text-xs">{unit}</span>
        </div>
      ))}
    </div>
  );
};

export function TotalDuration({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) {
  const calculateTimeLeft = () => {
    const difference = Math.abs(+new Date(endDate) - +new Date(startDate));
    let totalTime = {};

    if (difference > 0) {
      totalTime = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        mins: Math.floor((difference / 1000 / 60) % 60),
        secs: Math.floor((difference / 1000) % 60),
      };
    }

    return totalTime;
  };

  const totalTime = calculateTimeLeft();

  return (
    <div className="flex items-center gap-1">
      {Object.entries(totalTime).map(([unit, value]) => (
        <div key={unit} className="space-x-0.5">
          <span className="font-bold tabular-nums leading-3 tracking-wider">
            {value as number}
          </span>
          <span className="text-xs">{unit}</span>
        </div>
      ))}
    </div>
  );
}
