'use client';

import { Layer } from 'react-map-gl';

interface JourneyLiveProgressLayerProps {
  id: string;
}
/**
 * Displays the live track of a journey.
 */
export function JourneyLiveProgressLayer({
  id,
}: JourneyLiveProgressLayerProps) {
  return (
    <Layer
      id={id}
      source={id}
      type="line"
      paint={{
        'line-width': 2.5,
        'line-color': `hsl(200, 100%, 50%)`,
      }}
    />
  );
}
