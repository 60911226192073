'use client';
import { useMapTheme } from '../../context/map-context';
import { cn } from '@streetferret/utils';
import { Layer } from 'react-map-gl';

export function POIStadium() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'poi_stadium',
        type: 'symbol',
        source: 'openmaptiles',
        'source-layer': 'poi',
        minzoom: 15,
        filter: [
          'all',
          ['in', 'class', 'stadium', 'cemetery', 'attraction'],
          ['<=', 'rank', 3],
        ],
        layout: {
          'text-field': '{name}',
          'text-font': ['Americana-Bold'],
          'text-size': {
            stops: [
              [15, 8],
              [17, 9],
              [18, 10],
            ],
          },
          'text-transform': 'uppercase',
        },
        paint: {
          'text-color': cn({
            '#7d9c83': config.baseTheme === 'light',
            '#515151': config.baseTheme === 'dark',
          }),
          'text-halo-color': cn({
            '#f5f5f3': config.baseTheme === 'light',
            '#151515': config.baseTheme === 'dark',
          }),
          'text-halo-width': 1,
        },
      }}
    />
  );
}
export function POIPark() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'poi_park',
        type: 'symbol',
        source: 'openmaptiles',
        'source-layer': 'poi',
        minzoom: 15,
        filter: ['all', ['==', 'class', 'park']],
        layout: {
          'text-field': '{name}',
          'text-font': ['Americana-Bold'],
          'text-size': {
            stops: [
              [15, 8],
              [17, 9],
              [18, 10],
            ],
          },
          'text-transform': 'uppercase',
        },
        paint: {
          'text-color': cn({
            '#7d9c83': config.baseTheme === 'light',
            '#515151': config.baseTheme === 'dark',
          }),
          'text-halo-color': cn({
            '#f5f5f3': config.baseTheme === 'light',
            '#151515': config.baseTheme === 'dark',
          }),
          'text-halo-width': 1,
        },
      }}
    />
  );
}
export function POILayers() {
  return (
    <>
      <POIStadium />
      <POIPark />
    </>
  );
}
