'use client';
import { useMemo } from 'react';
import { type CircleLayer, Layer, type LineLayer } from 'react-map-gl';
import { USER_TILES_SOURCE_ID } from './user-tiles-source';
import { type Expression } from 'mapbox-gl';

export const ROUTE_SELECT_LAYER_INTERACTIVE_ID = 'route-base';
interface JourneyLayersProps {
  journeyId: bigint | null;
}
export function JourneyLayers({ journeyId }: JourneyLayersProps) {
  const progressLayer = useMemo(
    () => journeyProgressLayerJson({ journeyId }),
    [journeyId],
  );

  const trackLayerLZ = useMemo(
    () => journeyTrackLayerLowZoomJson({ journeyId }),
    [journeyId],
  );

  const trackLayerHZ = useMemo(
    () => journeyTrackLayerHighZoomJson({ journeyId }),
    [journeyId],
  );

  return (
    <>
      <Layer {...trackLayerHZ} beforeId="_road_overlays" />
      <Layer {...progressLayer} beforeId="_road_overlays" />
      <Layer {...trackLayerLZ} beforeId="_road_overlays" />
    </>
  );
}
const COLOR_COMPLETE = 'hsl(200, 100%, 50%)';
const COLOR_INCOMPLETE = 'hsl(0, 70%, 40%)';
const COLOR_TRACE = 'hsl(200, 30%, 40%)';
const COLOR_BYPASS = 'hsl(40, 15%, 50%)';

export const JOURNEY_PROGRESS_LINE_COLOR: Expression = [
  'case',
  ['==', ['get', 'bypass'], 1],
  COLOR_BYPASS,
  ['==', ['get', 'bypass'], 3],
  COLOR_BYPASS,
  ['==', ['get', 'complete'], 1],
  COLOR_COMPLETE,
  ['==', ['get', 'complete'], 3],
  COLOR_COMPLETE,
  COLOR_INCOMPLETE,
];

function journeyProgressLayerJson(config: JourneyLayersProps): LineLayer {
  const { journeyId } = config;
  return {
    id: 'journey-progress',
    source: USER_TILES_SOURCE_ID,
    'source-layer': 'journey',
    type: 'line',
    paint: {
      'line-width': 2,
      'line-color': JOURNEY_PROGRESS_LINE_COLOR,
    },
    minzoom: 2,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    filter: ['all', ['==', 'journeyID', Number(journeyId)], ['!has', 'bypass']],
  };
}

function journeyTrackLayerLowZoomJson(config: JourneyLayersProps): CircleLayer {
  const { journeyId } = config;
  return {
    id: 'journey-track-low-zoom',
    source: USER_TILES_SOURCE_ID,
    'source-layer': 'journey-track',
    type: 'circle',
    minzoom: 0,
    maxzoom: 11, // Visible up to zoom level 11
    paint: {
      'circle-radius': 2,
      'circle-color': COLOR_COMPLETE,
    },
    filter: ['==', ['get', `j${journeyId}`], 1],
  };
}

function journeyTrackLayerHighZoomJson(
  config: JourneyLayersProps,
): CircleLayer {
  const { journeyId } = config;
  return {
    id: 'journey-track-high-zoom',
    source: USER_TILES_SOURCE_ID,
    'source-layer': 'journey-track',
    type: 'circle',
    minzoom: 11, // Visible from zoom level 11 and above
    paint: {
      'circle-radius': 4,
      'circle-color': COLOR_TRACE,
    },
    filter: ['==', ['get', `j${journeyId}`], 1],
  };
}
